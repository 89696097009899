._2RgnXmOSz1EIt1MQ0uRTYx {
  position: absolute;
  color: white;
  font-weight: 300;
}

._3SY6t3Q6Ai1XpJPvQvCnKU {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

._3zORA-IC0dTMd4sWutRumM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 175ms ease-out;
  transition: opacity 175ms ease-out;
}
